import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import ServeNeeds from "../components/serve-needs"
import Contact from "../components/contact"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Home" 
    />
    <Hero />
    <ServeNeeds />
    <Contact />
    <Helmet>
      <script>
        0
      </script>
    </Helmet>
  </Layout>
)

export default IndexPage
