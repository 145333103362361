import React from "react"
import styled from "styled-components"

const ServeNeedsContainer = styled.div`
  background-color: #305244;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    color: #fdfdfd;
    text-align: center;
  }
  @media screen and (min-width: 450px) {
    padding: 32px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 48px 64px;
  }
  @media screen and (min-width: 700px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 64px 48px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 72px 48px;
  }
  @media screen and (min-width: 950px) {
    padding: 0 80px 48px;
  }
  @media screen and (min-width: 1050px) {
    padding: 0 96px 64px;
    h2{
      margin-bottom: 32px;
    }
  }
  @media screen and (min-width: 1250px) {
    padding: 0 96px 96px;
    justify-content: space-between;
  }
`

const USPContainer = styled.div`
  background-color: #fdfdfd;
  /* width: 100cw; */
  /* min-height: 267px; */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 32px 16px;
  margin: 32px 0 0 0;

  display: flex;
  flex-direction: column;

  align-items: center;

  @media screen and (min-width: 350px) {
    width: 80%;
  }
  @media screen and (min-width: 500px) {
    width: 70%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
  @media screen and (min-width: 700px) {
    width: 40%;
    min-height: 260px;
  }
  @media screen and (min-width: 800px) {
    width: 35%;
  }
  @media screen and (min-width: 1000px) {
    max-width: 300px;
    min-height: 316px;
  }
`

const SVGContainer = styled.div`
  width: 96px;
  height: auto;
  @media screen and (min-width: 450px) {
    width: 128px;
  }
  @media screen and (min-width: 900px) {
    width: 160px;
  }
`

const USPHeading = styled.div`
  margin-top: 16px;
  h4 {
    text-align: center;
    color: #305244;
    letter-spacing: -0.01em;
  }
  p {
    text-align: center;
    margin-top: 8px;
  }
`

const ServeNeeds = () => (
  <ServeNeedsContainer>
    <h2>KAEI is here to serve your needs</h2>
    {/* 1 */}
    <USPContainer>
      <SVGContainer>
        <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M48.2727 0L9 17.4545V43.6364C9 67.8545 25.7564 90.5018 48.2727 96C70.7891 90.5018 87.5455 67.8545 87.5455 43.6364V17.4545L48.2727 0ZM39.5455 69.8182L22.0909 52.3636L28.2436 46.2109L39.5455 57.4691L68.3018 28.7127L74.4545 34.9091L39.5455 69.8182Z"
            fill="#7BC8A8"
          />
        </svg>
      </SVGContainer>
      <USPHeading>
        <h4>Trusted Distributor</h4>
        <p>
          We have been operating for over 23 years. We have also generated
          substantial sales growth for the companies we’ve handled.
        </p>
      </USPHeading>
    </USPContainer>

    {/* 2 */}
    <USPContainer>
      <SVGContainer>
        <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M48 42.72C45.072 42.72 42.72 45.072 42.72 48C42.72 50.928 45.072 53.28 48 53.28C50.928 53.28 53.28 50.928 53.28 48C53.28 45.072 50.928 42.72 48 42.72ZM48 0C21.504 0 0 21.504 0 48C0 74.496 21.504 96 48 96C74.496 96 96 74.496 96 48C96 21.504 74.496 0 48 0ZM58.512 58.512L19.2 76.8L37.488 37.488L76.8 19.2L58.512 58.512Z"
            fill="#7BC8A8"
          />
        </svg>
      </SVGContainer>
      <USPHeading>
        <h4>Wide Coverage</h4>
        <p>
          We distribute consumer goods on a wholesale and retail basis to the
          provinces of Batangas and Mindoro.
        </p>
      </USPHeading>
    </USPContainer>

    {/* 3 */}
    <USPContainer>
      <SVGContainer>
        <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 18C0 8.05888 8.05888 0 18 0H78C87.9411 0 96 8.05888 96 18V78C96 87.9411 87.9411 96 78 96H18C8.05888 96 0 87.9411 0 78V18Z"
            fill="#7BC8A8"
          />
          <path
            d="M77.2464 36.0285L72.9455 42.4972C75.0196 46.634 76.0351 51.2207 75.9008 55.8463C75.7666 60.4719 74.4868 64.992 72.1763 69.0015H23.7133C20.7103 63.7919 19.466 57.7533 20.1648 51.781C20.8635 45.8086 23.4682 40.2203 27.5927 35.8447C31.7172 31.469 37.1419 28.5389 43.0626 27.4887C48.9833 26.4385 55.0849 27.3242 60.4627 30.0143L66.9314 25.7135C60.3455 21.4904 52.5335 19.598 44.745 20.339C36.9566 21.0801 29.6416 24.4117 23.9703 29.8011C18.299 35.1905 14.5989 42.3261 13.462 50.0667C12.3251 57.8073 13.8171 65.7056 17.6992 72.4981C18.3094 73.5551 19.1855 74.4339 20.2406 75.0473C21.2957 75.6607 22.4929 75.9874 23.7133 75.9947H72.1413C73.3736 75.9996 74.5853 75.6788 75.6537 75.0648C76.7221 74.4507 77.6093 73.5653 78.2254 72.4981C81.4471 66.9173 83.0639 60.5546 82.8973 54.1128C82.7307 47.6709 80.7872 41.4004 77.2813 35.9935L77.2464 36.0285ZM43.0146 59.9453C43.6641 60.5955 44.4353 61.1113 45.2843 61.4632C46.1333 61.8152 47.0433 61.9963 47.9623 61.9963C48.8813 61.9963 49.7913 61.8152 50.6403 61.4632C51.4892 61.1113 52.2605 60.5955 52.91 59.9453L72.7008 30.2591L43.0146 50.0499C42.3644 50.6994 41.8486 51.4707 41.4966 52.3196C41.1447 53.1686 40.9636 54.0786 40.9636 54.9976C40.9636 55.9166 41.1447 56.8266 41.4966 57.6756C41.8486 58.5245 42.3644 59.2958 43.0146 59.9453Z"
            fill="#FDFDFD"
          />
        </svg>
      </SVGContainer>
      <USPHeading>
        <h4>Excellent Service</h4>
        <p>
          We can guarantee excellent product distribution with our expertise in
          distribution and logistics management.
        </p>
      </USPHeading>
    </USPContainer>
  </ServeNeedsContainer>
)

export default ServeNeeds
